import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { getAppConfigProvider } from '@tcc-mono/shared/app-config';
import { environment } from '../environments/environment';
import { CoenenWebShellModule } from '@tcc-mono/coenen/web/shell/feature';
import { SharedTranslationModule } from '@tcc-mono/shared/translation';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    IonicModule.forRoot(),
    HttpClientModule,
    SharedTranslationModule.forRoot(environment),
    CoenenWebShellModule
  ],
  providers: [getAppConfigProvider(environment)],
  bootstrap: [AppComponent]
})
export class AppModule { }
